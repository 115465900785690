<template>
  <div>
    <van-nav-bar title="库存查询" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <van-tabs v-model:active="actives">
    <van-tab title="库存查询"></van-tab>
    <van-tab title="出库查询"></van-tab>
    <van-tab title="入库查询"></van-tab>
  </van-tabs>
  <van-field
      v-model="search"
      is-link
      readonly
      name="picker"
      label="搜索条件"
      placeholder="选择搜索条件"
      @click="searchPicker = true"
  />
  <van-popup v-model:show="searchPicker" position="bottom">
    <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="searchPicker = false"
    />
  </van-popup>
  <van-search v-model="value" placeholder="请输入搜索关键词" />
  <div v-if="active === 0" class="d_form">
    <div class="all">
      <div class="table-container">
        <table class="wide-table2">
          <tr>
            <th>序号</th>
            <th>物料名称</th>
            <th>物料编号</th>
            <th>库存数量</th>
            <th>批次</th>
            <th>仓库</th>
            <th>库区</th>
            <th>库位</th>
            <th>收容数</th>
            <th>库存状态</th>
          </tr>
          <tr v-for="item in list" :key="item.num">
            <td>{{ item.num }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.lot }}</td>
            <td>{{ item.warehouse }}</td>
            <td>{{ item.area }}</td>
            <td>{{ item.location }}</td>
            <td>{{ item.capacity }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div v-if="active === 1" class="d_form">
    <div class="all">
      <div class="table-container">
        <table class="wide-table2">
          <tr>
            <th>序号</th>
            <th>物料名称</th>
            <th>物料编码</th>
            <th>出库数量</th>
            <th>单位</th>
            <th>出库时间</th>
            <th>仓库</th>
            <th>供应商代码</th>
          </tr>
          <tr v-for="item in list1" :key="item.num">
            <td>{{ item.num }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.unit }}</td>
            <td>{{ item.time }}</td>
            <td>{{ item.warehouse }}</td>
            <td>{{ item.provider }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div v-if="active === 2" class="d_form">
    <div class="all">
      <div class="table-container">
        <table class="wide-table2">
          <tr>
            <th>序号</th>
            <th>物料名称</th>
            <th>物料编码</th>
            <th>入库数量</th>
            <th>单位</th>
            <th>入库时间</th>
            <th>仓库</th>
            <th>供应商代码</th>
          </tr>
          <tr v-for="item in list2" :key="item.num">
            <td>{{ item.num }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.unit }}</td>
            <td>{{ item.time }}</td>
            <td>{{ item.warehouse }}</td>
            <td>{{ item.provider }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router"
import { Toast } from 'vant';
import { ref } from 'vue'
const router = useRouter()
const active = ref(0)
const actives = ref(null)
const searchPicker = ref(false)
const search = ref(null);
const columns = [
  { text: '物料名称', value: 0 },
  { text: '仓库', value: 1 },
  { text: '物料编号', value: 2 },
  { text: '批次', value: 3 },
  { text: '出库时间', value: 4 },
  { text: '入库时间', value: 5 }
];
const list = ref([
  {
    num: 1,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    area: '库区B',
    location: '库位A',
    capacity: '10'
  },
  {
    num: 2,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    area: '库区B',
    location: '库位A',
    capacity: '10'
  },
  {
    num: 3,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    area: '库区B',
    location: '库位A',
    capacity: '10'
  },
  {
    num: 4,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    area: '库区B',
    location: '库位A',
    capacity: '10'
  },
  {
    num: 5,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    area: '库区B',
    location: '库位A',
    capacity: '10'
  }
]);
const list1 = ref([
  {
    num: 1,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 2,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 3,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 4,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 5,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  }
]);
const list2 = ref([
  {
    num: 1,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 2,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 3,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 4,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 5,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  }
]);
const loading = ref(false);
const finished = ref(false);
const onLoad = () => {
};
function back() {
  router.push({ path: "/index" })
}
function onConfirm() {
  searchPicker.value = false
}
</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}
.fz {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.fz div{
  width: 100px;
  display: flex;
  font-size: 0.3rem;
  margin-right: 10px;
}
.all{
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.flex{
  display: flex;
  width: 100%;
  overflow: scroll;
  justify-content: space-between;
}
</style>
